import ApiError from '@/core/api/errors/ApiError';
import VueI18n from 'vue-i18n';
import { ApiErrorResponseMessagesContract } from '@/core/api/errors/contracts/ApiErrorResponseContract';
import ApiErrorSchemaEnum from '@/core/api/support/ApiErrorSchemaEnum';
import Locale from '@/core/locale/Locale';

export default class ApiErrorTranslator {
  /**
   * Translation schema
   */
  protected i18n!: VueI18n;
  protected locales!: string;

  /**
   * Error symbols schema
   */
  protected symbols: string[] = [];

  /**
   * Constructor initializing translation schema
   * @param translations
   * @param symbols
   */
  constructor(locales?: string, symbols?: object) {
    this.i18n = Locale.i18n;
    // TODO think about conception of multi locales and symbols params from many modules
    this.locales = locales || '';
    this.symbols = symbols ? Object.keys(symbols) : [];
  }

  /**
   * Get translations list
   * TODO try to use it
   */
  public translate(error: ApiError): string[] {
    const defaultMessage: string = 'api.validation.error.unknown';

    if (error.hasMessages()) {
      // error has specific messages
      const list: string[] = [];
      error.getMessages().forEach((item: ApiErrorResponseMessagesContract) => {
        if (item.type === ApiErrorSchemaEnum.SYMBOL && this.isSymbolAvailable(item.message)) {
          // message as symbol to translate
          list.push(this.convert(`${this.locales}.${item.message}`));
        } else if (item.type === ApiErrorSchemaEnum.MESSAGE) {
          // translated message from api
          list.push(item.message);
        }
      });
      return list.length > 0 ? list : [this.convert(defaultMessage)];
    } else if (error.hasSymbol() && this.isSymbolAvailable(error.getSymbol())) {
      // error symbol translating by i18n schema
      return [this.convert(`${this.locales}.${error.getSymbol()}`)];
    } else {
      // unknown error default translation
      return [this.convert(defaultMessage)];
    }
  }

  /**
   * Convert symbol to translated phrase by i18n service
   * @param local
   */
  private convert(local: string) {
    return this.i18n ? this.i18n.t(local).toString() : '';
  }

  /**
   * Check if can handle validation error symbol
   */
  private isSymbolAvailable(symbol: string) {
    return (
      !!this.symbols &&
      this.symbols.some((s: string) => {
        return symbol === s;
      })
    );
  }
}
